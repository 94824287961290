import { Button, type ButtonProps } from 'ui';
import { useFormContext } from 'remix-validated-form';

export type SubmitButtonProps = ButtonProps & {
  disabled?: boolean;
  form?: string;
};

export function SubmitButton({ children, form, ...props }: SubmitButtonProps) {
  const { isSubmitting } = useFormContext(form);

  return (
    <Button {...props} disabled={isSubmitting || props.disabled} type="submit" form={form} loading={isSubmitting}>
      {children}
    </Button>
  );
}
